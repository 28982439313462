import { useEffect, useState } from "react";
import styles from "./DecretosArari.module.css";
import NavMenu from "../../../../components/public/others/Navbar";
import Footer from "../../../../components/public/others/Footer";

const DecretosArari = () => {
  const [decretos, setdecretos] = useState([]);
  const [busca, setBusca] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const itensPorPagina = 5;

  useEffect(() => {
    fetch("/decretos/lista.json")
      .then((res) => res.json())
      .then((data) => setdecretos(data));
  }, []);

  const decretosFiltradas = decretos.filter((lei) =>
    lei.titulo
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        busca
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      )
  );

  const inicio = (paginaAtual - 1) * itensPorPagina;
  const fim = inicio + itensPorPagina;
  const decretosPaginadas = decretosFiltradas.slice(inicio, fim);

  const totalPaginas = Math.ceil(decretosFiltradas.length / itensPorPagina);

  return (
    <>
      <NavMenu />
      <div className={styles.container}>
        <h1 className={styles.title}>Decretos Municipais</h1>
        <input
          type="text"
          placeholder="Pesquisar decreto..."
          value={busca}
          onChange={(e) => {
            setBusca(e.target.value);
            setPaginaAtual(1);
          }}
          className={styles.input}
        />
        <ul className={styles.list}>
          {decretosPaginadas.map((decreto) => (
            <li key={decreto.id} className={styles.listItem}>
              <a
                href={`/decretos/${decreto.arquivo}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {decreto.titulo}
              </a>
            </li>
          ))}
        </ul>

        <div className={styles.pagination}>
          <button
            className={styles.pageButton}
            disabled={paginaAtual === 1}
            onClick={() => setPaginaAtual(paginaAtual - 1)}
          >
            Anterior
          </button>
          <span className={styles.pageInfo}>
            Página {paginaAtual} de {totalPaginas}
          </span>
          <button
            className={styles.pageButton}
            disabled={
              paginaAtual === totalPaginas || decretosFiltradas.length === 0
            }
            onClick={() => setPaginaAtual(paginaAtual + 1)}
          >
            Próxima
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DecretosArari;
