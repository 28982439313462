import { useEffect, useState } from "react";
import styles from "./LeisArari.module.css";
import NavMenu from "../../../../components/public/others/Navbar";
import Footer from "../../../../components/public/others/Footer";

const LeisArari = () => {
  const [leis, setLeis] = useState([]);
  const [busca, setBusca] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const itensPorPagina = 5;

  useEffect(() => {
    fetch("/leis/lista.json")
      .then((res) => res.json())
      .then((data) => setLeis(data));
  }, []);

  const leisFiltradas = leis.filter((lei) =>
    lei.titulo
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        busca
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      )
  );

  const inicio = (paginaAtual - 1) * itensPorPagina;
  const fim = inicio + itensPorPagina;
  const leisPaginadas = leisFiltradas.slice(inicio, fim);

  const totalPaginas = Math.ceil(leisFiltradas.length / itensPorPagina);

  return (
    <>
      <NavMenu />
      <div className={styles.container}>
        <h1 className={styles.title}>Leis Municipais</h1>
        <input
          type="text"
          placeholder="Pesquisar lei..."
          value={busca}
          onChange={(e) => {
            setBusca(e.target.value);
            setPaginaAtual(1);
          }}
          className={styles.input}
        />
        <ul className={styles.list}>
          {leisPaginadas.map((lei) => (
            <li key={lei.id} className={styles.listItem}>
              <a
                href={`/leis/${lei.arquivo}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {lei.titulo}
              </a>
            </li>
          ))}
        </ul>

        <div className={styles.pagination}>
          <button
            className={styles.pageButton}
            disabled={paginaAtual === 1}
            onClick={() => setPaginaAtual(paginaAtual - 1)}
          >
            Anterior
          </button>
          <span className={styles.pageInfo}>
            Página {paginaAtual} de {totalPaginas}
          </span>
          <button
            className={styles.pageButton}
            disabled={
              paginaAtual === totalPaginas || leisFiltradas.length === 0
            }
            onClick={() => setPaginaAtual(paginaAtual + 1)}
          >
            Próxima
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LeisArari;
